import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentRouteService {
  readonly #router = inject(Router);
  readonly #location = inject(Location);

  get(): Observable<string> {
    return this.#router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => event.urlAfterRedirects),
      startWith(this.#location.path(false)),
      map((url) => this.#extractCurrentRoute(url))
    );
  }

  #extractCurrentRoute(url: string): string {
    const arrayFromSeparators = url.split(/\/|\?/);
    const [firstNonNullString] = arrayFromSeparators.filter((value) => Boolean(value));
    return firstNonNullString;
  }
}
