import { inject } from '@angular/core';
import { FEATURES_ROUTING } from '@features/routes';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import {
  BUILDER_MENU_ACTIONS,
  loadMenuPartnerIdSuccess,
  loadMenuSettingsSuccess,
  toggleMenuEntryAfterNavigate,
  updateDynamicKeysAfterNavigate,
} from '@stores/builder-menu/builder-menu.actions';
import { PARTNER_REVISION_ACTIONS } from '@stores/partner/partner-revision/partner-revision.actions';
import { partnerId } from '@stores/partner/partner-revision/partner-revision.selectors';
import { selectRouteParams, selectUrl } from '@stores/router/router.selectors';
import { MenuWebservice } from '@webservices/menu/menu.webservice';
import { filter, map, switchMap } from 'rxjs';

/*
  Listen to Partner Revision State
  When PartnerId is Set
  triggers an action to set absolute route for each menu Item
*/
export const loadMenuPartnerId$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(PARTNER_REVISION_ACTIONS.LOAD_PARTNER_ID_SUCCESS),
      switchMap(() => store.select(partnerId)),
      filter(Boolean),
      map((partnerId) => loadMenuPartnerIdSuccess({ partnerId }))
    ),
  { functional: true }
);

/*
  Listen to Menu Reducer
  When absolute routes have been set
  triggers an action to disable some route according to API response
*/
export const loadMenuSettings$ = createEffect(
  (actions$ = inject(Actions), menuWebservice = inject(MenuWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(BUILDER_MENU_ACTIONS.LOAD_MENU_PARTNER_ID_SUCCESS),
      switchMap(() => store.select(partnerId)),
      filter(Boolean),
      switchMap((partnerId) => menuWebservice.getSettings(partnerId)),
      map((settings) => loadMenuSettingsSuccess({ settings }))
    ),
  { functional: true }
);

/*
  Listen to Menu Reducer and Router State
  filter route to match only '/partners'
  triggers an action to disable some route according to API response
*/
export const toggleMenuEntriesAfterNavigate$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(BUILDER_MENU_ACTIONS.LOAD_MENU_PARTNER_ID_SUCCESS, ROUTER_NAVIGATED),
      switchMap(() => store.select(selectUrl)),
      filter((url) => url.startsWith(`/${FEATURES_ROUTING.Partners}/`)),
      map((url) => toggleMenuEntryAfterNavigate({ url }))
    ),
  { functional: true }
);

/*
  Listen to Menu Reducer and Router State
  filter params to match 'partnerId'
  triggers an action to update menu entries with
  dynamic key found in builder menu config
*/
export const updateDynamicKeysAfterNavigate$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(BUILDER_MENU_ACTIONS.LOAD_MENU_PARTNER_ID_SUCCESS, ROUTER_NAVIGATED),
      switchMap(() => store.select(selectRouteParams)),
      filter((params) => Boolean(params['partnerId'])),
      map((params) => updateDynamicKeysAfterNavigate({ params }))
    ),
  { functional: true }
);
