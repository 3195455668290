import type { HttpErrorResponse } from '@angular/common/http';

import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  lockPlatform,
  lockPlatformCall,
  lockService,
  lockServiceCall,
  unlockPlatform,
  unlockPlatformCall,
  unlockService,
  unlockServiceCall,
} from '@stores/lock/lock.actions';
import { partnerId } from '@stores/partner/partner-revision/partner-revision.selectors';
import { selectQueryParam } from '@stores/router/router.selectors';
import { PartnerServiceWebservice } from '@webservices/partner-api/partner-service.webservice';
import { PartnerWebservice } from '@webservices/partner-api/partner.webservice';
import { userId as userIdFromStore } from '@wizbii-utils/angular/stores';
import { catchError, combineLatest, filter, map, switchMap, throwError } from 'rxjs';

export const lockPlatformEffect = createEffect(
  (actions$ = inject(Actions), partnerWebservice = inject(PartnerWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(lockPlatformCall),
      switchMap(() => store.select(partnerId)),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => partnerWebservice.lock(partnerId)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 409) {
          const notLock = store.selectSignal(selectQueryParam('not-lock'))();
          store.dispatch(
            lockPlatform({
              userId: error.error.userId as string,
              overwriteLock: notLock === 'true',
            })
          );
        }

        return throwError(() => error);
      }),
      switchMap(() => store.select(userIdFromStore)),
      map((userId) => {
        return lockPlatform({
          userId: userId ?? undefined,
          overwriteLock: false,
        });
      })
    ),
  { functional: true }
);

export const unlockPlatformEffect = createEffect(
  (actions$ = inject(Actions), partnerWebservice = inject(PartnerWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(unlockPlatformCall),
      switchMap(() => store.select(partnerId)),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => partnerWebservice.unlock(partnerId)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 409) {
          store.dispatch(unlockPlatform({ userId: error.error.userId }));
        }

        return throwError(() => error);
      }),
      switchMap(() => store.select(userIdFromStore)),
      map((userId) => unlockPlatform({ userId: userId ?? undefined }))
    ),
  { functional: true }
);

export const lockServiceEffect = createEffect(
  (actions$ = inject(Actions), partnerServiceWebservice = inject(PartnerServiceWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(lockServiceCall),
      switchMap(() => store.select(partnerId)),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => partnerServiceWebservice.lock(partnerId)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 409) {
          const notLock = store.selectSignal(selectQueryParam('not-lock'))();
          store.dispatch(
            lockService({
              userId: error.error.userId,
              overwriteLock: notLock === 'true',
            })
          );
        }

        return throwError(() => error);
      }),
      switchMap(() => combineLatest([store.select(userIdFromStore), store.select(selectQueryParam('not-lock'))])),
      map(([userId, notLock]) => {
        return lockService({
          userId: userId ?? undefined,
          overwriteLock: notLock === 'true',
        });
      })
    ),
  { functional: true }
);

export const unlockServiceEffect = createEffect(
  (actions$ = inject(Actions), partnerServiceWebservice = inject(PartnerServiceWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(unlockServiceCall),
      switchMap(() => store.select(partnerId)),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => partnerServiceWebservice.unlock(partnerId)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 409) {
          store.dispatch(unlockService({ userId: error.error.userId }));
        }

        return throwError(() => error);
      }),
      switchMap(() => store.select(userIdFromStore)),
      map((userId) => unlockService({ userId: userId ?? undefined }))
    ),
  { functional: true }
);
