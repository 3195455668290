import type { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import type { Observable } from 'rxjs';

type PatchRequestBodyType<T> = Record<string, T | T[] | Record<string, T>>;

const handleRequestBody = (httpRequest: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  httpRequest = httpRequest.clone({ body: transformBlankStringToNull(httpRequest.body) });
  return next(httpRequest);
};

const transformBlankStringToNull = <T>(obj: PatchRequestBodyType<T>): PatchRequestBodyType<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      return { ...acc, [key]: value };
    }
    if (typeof value === 'number') {
      return { ...acc, [key]: value };
    }
    if (typeof value === 'string' && value.length === 0) {
      return { ...acc, [key]: null };
    }
    if (typeof value === 'object' && value !== null) {
      return { ...acc, [key]: transformBlankStringToNull(value as PatchRequestBodyType<unknown>) };
    }
    return { ...acc, [key]: value };
  }, {});
};

export const reqBodyInterceptorFn: HttpInterceptorFn = (request, next) => {
  if ((request.method === 'PATCH' || request.method === 'PUT') && request.body !== null) {
    return handleRequestBody(request, next);
  }
  return next(request);
};
