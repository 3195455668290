import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Credentials, CredentialsFetcher } from '@wizbii-utils/angular/algolia';
import type { Observable } from 'rxjs';

@Injectable()
export class AlgoliaCredentialsService implements CredentialsFetcher {
  readonly #http = inject(HttpClient);

  public readonly fetchCredentials = (): Observable<Credentials> =>
    this.#http.get<Credentials>(`${environment.api.obendy}/v3/_credentials/algolia`);
}
