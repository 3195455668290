import type { BrowserConfig } from '@bugsnag/browser';

import { environment } from '@env/environment';

export const bugsnagConfig: BrowserConfig & { isDeployed: boolean } = {
  apiKey: environment.bugsnag.apiKey,
  appVersion: environment.version,
  autoTrackSessions: true,
  releaseStage: environment.platform,
  enabledReleaseStages: ['prod', 'staging', 'dev', 'qa-01'],
  isDeployed: environment.production,
};
