import { inject } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import {
  loadAllServiceCategory,
  setAllServiceCategoryFailed,
  setAllServiceCategorySuccess,
} from '@stores/service/services-categories/services-categories.actions';
import { ServiceCategoryWebService } from '@webservices/service/service-category.webservice';
import { catchError, map, of, switchMap } from 'rxjs';

export const getServicesCategoriesEffect = createEffect(
  (actions$ = inject(Actions), serviceCategoryWebService = inject(ServiceCategoryWebService)) =>
    actions$.pipe(
      ofType(loadAllServiceCategory, ROOT_EFFECTS_INIT),
      switchMap(() => serviceCategoryWebService.getAll()),
      map((servicesCategories) => setAllServiceCategorySuccess({ servicesCategories })),
      catchError(() => of(setAllServiceCategoryFailed()))
    ),
  { functional: true }
);
